import MercuryButton from '@/components/content/button/Button';

import { useSession } from '@/hooks/useSession';
import { useLogout } from '../services/logout';
import { useToast } from '@/hooks/useToast';

const Logout = () => {
  const { createToast } = useToast();
  const {
    mutation: { mutate: serverLogout, isPending: isLoginOut },
    clientLogout,
  } = useLogout();
  const { stopSession } = useSession();

  const onClick = () => {
    serverLogout('', {
      onSuccess: () => {
        stopSession();
        clientLogout();
      },
      onError: () => {
        createToast('We had an issue logging you out. Please try again.', 'error');
      },
    });
  };

  return (
    <MercuryButton
      id="logout"
      justify="flex-start"
      onClick={onClick}
      disabled={isLoginOut}
      variant="subtle"
      size="md"
      loading={isLoginOut}
    >
      Logout
    </MercuryButton>
  );
};

export default Logout;
