import type { DateSegment } from 'react-stately';

export function getDateFieldSegments(segments: Array<DateSegment>): Array<DateSegment> {
  const find = (type: string) => segments.find((segment) => segment.type === type);

  const daySegment = find('day');
  const monthSegment = find('month');
  const yearSegment = find('year');
  const separatorSegment = find('literal');

  if (!daySegment || !monthSegment || !yearSegment || !separatorSegment) {
    throw new Error('Invalid date field segments');
  }

  const formattedDaySegment = {
    ...daySegment,
    text: daySegment.text.padStart(2, '0'),
  };

  const formattedMonthSegment = {
    ...monthSegment,
    text: monthSegment.text.padStart(2, '0'),
  };

  const formattedSeparatorSegment = { ...separatorSegment, text: '-' };

  return [
    yearSegment,
    formattedSeparatorSegment,
    formattedMonthSegment,
    formattedSeparatorSegment,
    formattedDaySegment,
  ];
}
