import type { FieldValues } from 'react-hook-form';

import MercuryNativeSelect, { type MercuryNativeSelectProps } from '../../form/components/NativeSelect';

interface TimezoneProps<TFormValues extends FieldValues> extends MercuryNativeSelectProps<TFormValues> {
}

const Timezone = <TFormValues extends FieldValues>({
  ...props
}: TimezoneProps<TFormValues>) => {
  const timezones = [
    {
      label: 'Greenwich Mean Time (GMT) UTC+0',
      value: '+0',
    },
    {
      label: 'Central European Time (CET) UTC+1',
      value: '+1',
    },
    {
      label: 'Eastern European Time (EET) UTC+2',
      value: '+2',
    },
    {
      label: 'Further-Eastern European Time (FET) UTC+3',
      value: '+3',
    },
  ];
  return (
    <MercuryNativeSelect
      data={timezones}
      {...props}
    />
  );
};

export default Timezone;
