import { Text } from '@mantine/core';

import MercuryNativeSelect from '@/components/content/form/components/NativeSelect';
import Interval from './Interval';
import Daily from './Daily';
import Weekly from './Weekly';
import Monthly from './Monthly';

import { useNotificationSchedule } from './useNotificationSchedule';

import { type FieldsetProps, type FrequencyNames, FREQUENCY } from '../../types';

interface ScheduleProps extends FieldsetProps { }

const Schedule = ({ control, setValue, resetField }: ScheduleProps) => {
  const {
    field,
    frequency,
    error,
    onChangeHandler,
    changeFrequency,
  } = useNotificationSchedule({ control, setValue, resetField });

  return (
    <fieldset>
      <legend>
        Schedule<sup>*</sup>

        <Text size="xs" span display="block">When do you want this rule to run on?</Text>
      </legend>

      <div>
        <MercuryNativeSelect
          name="frequency.type"
          label="Frequency"
          onChange={(e) => changeFrequency(e.target.value as FrequencyNames)}
          error={error}
          value={frequency}
          placeholder="Select Frequency"
          data={Object.values(FREQUENCY)}
          size="xs"
        />

        {frequency === FREQUENCY.INTERVAL && <Interval onChange={onChangeHandler} error={error} value={field.value} />}
        {frequency === FREQUENCY.DAILY && <Daily onChange={onChangeHandler} error={error} value={field.value} />}
        {frequency === FREQUENCY.WEEKLY && <Weekly onChange={onChangeHandler} error={error} value={field.value} />}
        {frequency === FREQUENCY.MONTHLY && <Monthly onChange={onChangeHandler} error={error} value={field.value} />}

        <input type="hidden" name={field.name} value={field.value} />
      </div>
    </fieldset>
  );
};

export default Schedule;
