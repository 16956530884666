import { superstructResolver } from '@hookform/resolvers/superstruct';

import Form from '@/components/content/form/Form';
import MercuryTextInput from '@/components/content/form/components/TextInput';
import MercuryFile from '@/components/content/form/components/File';
import MercuryTextArea from '@/components/content/form/components/TextArea';
import MercuryNativeSelect from '@/components/content/form/components/NativeSelect';
import InputDisplayControl from '@/components/content/form/components/InputDisplayControl';

import { useSupportForm } from './useSupportForm';
import { useAuth } from '@/hooks/useAuth';
import { getEntries, objectHasValues } from '@/utils';
import { today } from '@/components/content/date/datepicker/presets';

import { type SupportFormFields, SupportFormLabels } from './types';
import type { FetchError } from '@/types/api';

interface FormProps {
  prefillData?: Partial<SupportFormFields>;
  attachment?: boolean;
  subjects: Array<string>;
  name: string;
  disabledFields?: { [key in keyof SupportFormFields]?: boolean };
  hideFields?: { [key in keyof SupportFormFields]?: boolean };
  onSuccess?: VoidFunction;
  onError?: (error: FetchError) => void;
  triggerOtherOn?: string;
  relationship?: string;
}

const SupportForm = ({
  prefillData,
  attachment,
  disabledFields,
  subjects,
  hideFields,
  onSuccess,
  onError,
  name,
  triggerOtherOn,
  relationship,
}: FormProps) => {
  const { onSubmit, isPostingRfi, schema } = useSupportForm({
    onSuccess,
    onError,
    type: prefillData?.to,
    relationship,
  });
  const { userInfo: { subclients } } = useAuth();
  const hasSubclients = objectHasValues(subclients);

  return (
    <Form<SupportFormFields>
      onSubmit={onSubmit}
      options={{ resolver: superstructResolver(schema), defaultValues: prefillData }}
      submitButton={{
        text: 'Submit',
        disabled: isPostingRfi,
      }}
      name={name}
      fields={SupportFormLabels}
    >
      {({ register, watch, formState: { errors }, resetField }) => (
        <>
          {!hideFields?.subclient_id && hasSubclients && (
            <MercuryNativeSelect<SupportFormFields>
              register={register}
              name="subclient_id"
              data={getEntries(subclients).map(([value, label]) => ({ value, label }))}
              // eslint-disable-next-line max-len
              description="Select a subclient to send the request in their behalf. If you do not select a subclient, the request will be sent as yourself"
              label={SupportFormLabels.subclient}
              error={errors?.subclient_id?.message}
              disabled={disabledFields?.subclient_id}
            />
          )}

          {!hideFields?.subject && (
            <MercuryNativeSelect<SupportFormFields>
              register={register}
              name="subject"
              data={subjects.map((subject) => ({ label: subject, value: subject }))}
              label={SupportFormLabels.subject}
              error={errors?.subject?.message}
              disabled={disabledFields?.subject}
              required
            />
          )}

          {triggerOtherOn && !hideFields?.otherSubject && (
            <InputDisplayControl<SupportFormFields> watch={watch} watchFieldName="subject" watchValue={triggerOtherOn}>
              <MercuryTextInput<SupportFormFields>
                register={register}
                label={SupportFormLabels.otherSubject}
                type="text"
                name="otherSubject"
                error={errors?.otherSubject?.message}
                placeholder="Write a subject"
                disabled={disabledFields?.otherSubject}
                required
              />
            </InputDisplayControl>
          )}

          <MercuryTextInput<SupportFormFields>
            register={register}
            label={SupportFormLabels.dueDate}
            type="date"
            min={today.add({ days: 1 }).toString()}
            name="due_date"
            error={errors?.due_date?.message}
            hidden={hideFields?.due_date}
            required
          />

          {!hideFields?.description && (
            <MercuryTextArea<SupportFormFields>
              register={register}
              name="description"
              label={SupportFormLabels.description}
              error={errors?.description?.message}
              displayError
              required
            />
          )}

          {attachment && (
            <MercuryFile<SupportFormFields>
              register={register}
              name="attachment"
              label={SupportFormLabels.attachment}
              watch={watch}
              resetField={resetField}
            />
          )}

          <MercuryTextInput
            register={register}
            type="hidden"
            name="to"
            hidden
          />

          <MercuryTextInput
            register={register}
            type="hidden"
            name="ticket_id"
            hidden
          />
        </>
      )}
    </Form>
  );
};

export default SupportForm;
