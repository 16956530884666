import TableWrapper from '@/features/tablewrapper/TableWrapper';
import { MercuryTableProvider } from '@/features/tablewrapper/MercuryTableContext';
import { MercuryCol, MercuryGrid } from '@/components/grid/Grid';

import { notificationRulesTable } from './notificationrules.table';
import { settingsKeys } from '../../services/keys';

import type { Notification } from '@/client/features/notificationsystem/types';

const NotificationRules = () => (
  <MercuryGrid>
    <MercuryCol>
      <MercuryTableProvider
        columns={notificationRulesTable}
        queryKey={settingsKeys.notificationRules()}
        tableEndpoint={() => () => 'mercury/rules/'}
        dataTransform={(data: { total: number; data: Array<Notification> }) => (
          {
            tableData: data.data,
            total: data.total,
          }
        )}
        basic
      >
        <TableWrapper options={{ noHighlight: true }} />
      </MercuryTableProvider>
    </MercuryCol>
  </MercuryGrid>
);

export default NotificationRules;
