import { createCalendar } from '@internationalized/date';
import { useRef } from 'react';
import { AriaDateFieldProps, DateValue, useLocale, useDateField } from 'react-aria';
import { useDateFieldState } from 'react-stately';

import { getDateFieldSegments } from './getDateFieldSegments';

export const useDatePicker = (props: AriaDateFieldProps<DateValue>) => {
  const ref = useRef(null);
  const { locale } = useLocale();
  const state = useDateFieldState({ ...props, locale, createCalendar });
  const segments = getDateFieldSegments(state.segments);
  const { labelProps, fieldProps } = useDateField(props, state, ref);

  return {
    labelProps,
    fieldProps,
    segments,
    ref,
    state,
  };
};
