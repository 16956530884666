import { type ChangeEvent } from 'react';
import { Group } from '@mantine/core';

import MercuryNumberInput from '@/components/content/form/components/NumberInput';
import MercuryNativeSelect from '@/components/content/form/components/NativeSelect';

import { parseInterval, stringifyInterval } from './parsers';
import { type IntervalNames, INTERVALS } from '../../types';

interface IntervalProps {
  onChange: (value: string | null) => void
  error?: string;
  value: string;
}

const Interval = ({ value, error, onChange }: IntervalProps) => {
  const parsed = parseInterval(value);
  const frequency = parsed?.frequency ?? INTERVALS.MINUTES;

  const onFrequencyChange = (val: string | number) => {
    onChange(val ? stringifyInterval(val, frequency) : null);
  };

  const onIntervalChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const val = e.target.value as IntervalNames;
    onChange(val ? stringifyInterval(parsed?.value ?? 0, val) : null);
  };

  const field = {
    [INTERVALS.MINUTES]: {
      min: 10,
      description: 'Min amount of minutes is 10',
    },
    [INTERVALS.HOURS]: {
      min: 1,
      description: 'Min amount of hours is 1',
    },
    [INTERVALS.DAYS]: {
      min: 1,
      description: 'Min amount of days is 1',
    },
  }[frequency];

  return (
    <Group gap={5} align="flex-start" mt={10}>
      <MercuryNumberInput
        name="value"
        error={error}
        label="Every"
        value={parsed?.value}
        description={field?.description}
        size="xs"
        wrapperProps={{ styles: { root: { flex: '1' } } }}
        onChange={onFrequencyChange}
        min={field?.min}
      />

      <MercuryNativeSelect
        name="frequency"
        label="Frequency"
        data={Object.values(INTERVALS)}
        value={frequency}
        onChange={onIntervalChange}
        disabled={!parsed?.value}
        size="xs"
      />
    </Group>
  );
};

export default Interval;
