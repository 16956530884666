import { UseQueryOptions, useQuery } from '@tanstack/react-query';

import { fetchData } from '@/lib/fetch';
import { useUserId } from '@/hooks/useUserId';

import { divideByCategory } from '../util/divideByCategory';

import type { FiltersMeta } from '@/types/meta';
import type { MetaBaseProps, MetaRequest } from '../types';
import type { UserId } from '@/types/api';

interface MetaResponse {
  filters: FiltersMeta;
}

const metaSelect = (customMeta: MetaRequest['customMeta'] = []) => (data: MetaResponse) => {
  const meta = [...customMeta, ...data.filters];

  const renderMeta = divideByCategory(meta);

  const defaultValues = meta.reduce((accu, curr) => {
    if (curr.default_choice) {
      accu[curr.name] = curr.default_choice;
    }

    return accu;
  }, {} as Record<string, any>);

  const hasDefaultValues = Object.keys(defaultValues).length > 0;

  return {
    meta: renderMeta,
    defaultValues: hasDefaultValues ? defaultValues : undefined,
  };
};

export const buildMetaQuery = ({
  userId,
  queryKey,
  endpoint,
  customEndpoint,
}: MetaBaseProps & { userId?: UserId }): Omit<UseQueryOptions<MetaResponse>, 'select'> => ({
  queryKey,
  queryFn: () => fetchData({ endpoint: customEndpoint || `mercury/${endpoint}${userId ? `/${userId}` : ''}/meta` }),
});

export const useGetMeta = ({
  customMeta = [],
  query,
}: { customMeta?: FiltersMeta, query: (userId: UserId) => Omit<UseQueryOptions<MetaResponse>, 'select'> }) => {
  const userId = useUserId();

  return useQuery({
    ...query(userId),
    refetchOnMount: false,
    select: metaSelect(customMeta),
  });
};
