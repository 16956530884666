import At from './At';

import { parseDaily, stringifyDaily } from './parsers';

interface DailiyProps {
  onChange: (value: string) => void;
  error?: string;
  value: string;
}

const Daily = ({ value, error, onChange }: DailiyProps) => {
  const parsed = parseDaily(value);

  return (
    <At
      parsed={parsed}
      onChange={(minute, hour, timezone) => onChange(stringifyDaily(minute, hour, timezone))}
      error={error}
    />
  );
};

export default Daily;
