import { getLocalTimeZone, today as getToday } from '@internationalized/date';

export const today = getToday(getLocalTimeZone());
export const lastWeek = today.subtract({ days: 7 });
const yesterday = today.subtract({ days: 1 });
const lastMonth = today.subtract({ months: 1 });

export const defaultPresets = {
  'now/': {
    label: 'Today',
    value: 'now/',
    rangeDateValue: () => ({ start: today, end: today }),
  },
  'now-1d/': {
    label: 'Yesterday',
    value: 'now-1d/',
    rangeDateValue: () => ({ start: yesterday, end: yesterday }),
  },
  'now-7d/': {
    label: 'Last 7 days',
    value: 'now-7d/',
    rangeDateValue: () => ({ start: lastWeek, end: today }),
    preview: `${lastWeek.toString()} to ${today.toString()}`,
  },
  'now-30d/': {
    label: 'Last 30 days',
    value: 'now-30d/',
    rangeDateValue: () => ({ start: lastMonth, end: today }),
    preview: `${lastMonth.toString()} to ${today.toString()}`,
  },
};
