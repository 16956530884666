import MetaComponent from './MetaComponent';

import type { FiltersMeta } from '@/types/meta';
import type { MetaStore } from '@/hooks/useMetaStore';

import classes from './Meta.module.css';

interface MetaContainerProps {
  meta: FiltersMeta;
  store: MetaStore;
}

const MetaContainer = ({ meta, store }: MetaContainerProps) => (
  <div className={classes.container}>
    {meta.map((m) => <MetaComponent key={m.name} meta={m} store={store} />)}
  </div>
);

export default MetaContainer;
