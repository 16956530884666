import { Checkbox, Group, Stack } from '@mantine/core';

import At from './At';

import { parseWeekly, stringifyWeekly } from './parsers';

interface WeeklyProps {
  onChange: (value: string) => void;
  error?: string;
  value: string;
}

const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const Weekly = ({ value, error, onChange }: WeeklyProps) => {
  const parsed = parseWeekly(value);

  const onWeekChange = (val: Array<string>) => {
    onChange(stringifyWeekly(parsed?.minutes, parsed?.hours, val, parsed?.timezone));
  };

  return (
    <Stack>
      <Checkbox.Group value={parsed?.weekdays} onChange={onWeekChange} mt={10} label="Every" size="xs" error={!!error}>
        <Group mt={5} align="center">
          {days.map((day, index) => (
            <Checkbox
              key={day}
              value={`${index}`}
              label={day}
              size="xs"
              styles={{ label: { paddingInlineStart: 5 } }}
            />
          ))}
        </Group>
      </Checkbox.Group>

      <At
        parsed={parsed}
        onChange={(minute, hour, timezone) => (
          onChange(stringifyWeekly(minute, hour, parsed?.weekdays as Array<string>, timezone))
        )}
        error={error}
        disabled={!parsed?.weekdays.length}
      />
    </Stack>
  );
};

export default Weekly;
