import { useState, type KeyboardEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { ActionIcon, MantineSize, TextInput } from '@mantine/core';

import SearchIcon from '@/assets/icons/content/search.svg';

interface SearchBarProps {
  onSearch?: VoidFunction;
  size?: MantineSize;
}

const NavBarSearchBar = ({ onSearch, size }: SearchBarProps) => {
  const [search, setSearch] = useState('');
  const navigate = useNavigate();

  const handleSearch = () => {
    if (!search.length) return;

    onSearch?.();
    navigate(`/search?q=${encodeURIComponent(search)}`);
  };

  const onKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    const inputValue = search.trim();
    const inputValueLength = inputValue.length;

    if (event.key === 'Enter' && inputValueLength > 0 && !event.nativeEvent.isComposing) {
      event.preventDefault();
      handleSearch();
    }
  };

  return (
    <TextInput
      value={search}
      onChange={(e) => setSearch(e.currentTarget.value)}
      onKeyDown={onKeyDown}
      size={size}
      role="searchbox"
      id="mercury-search"
      placeholder="Search for a keyword or id in the Mercury Knowledge Base"
      rightSection={(
        <ActionIcon
          variant="subtle"
          onClick={handleSearch}
          aria-label="Search"
          size={20}
        >
          <SearchIcon />
        </ActionIcon>
      )}
    />
  );
};

export default NavBarSearchBar;
