import type { ChangeEvent } from 'react';
import { SimpleGrid } from '@mantine/core';
import { Time } from '@internationalized/date';
import type { TimeValue } from 'react-aria';

import TimeField from '@/components/content/date/timefield/TimeField';
import Timezone from '@/components/content/date/timezone/Timezone';

interface AtProps {
  onChange: (minute?: number, hour?: number, timezone?: string) => void;
  error?: string;
  parsed?: { minutes: number, hours: number, timezone: string } | null;
  disabled?: boolean;
}

const At = ({ onChange, disabled, error, parsed }: AtProps) => {
  const onTimeChange = (value: TimeValue) => {
    onChange(value.minute, value.hour, parsed?.timezone);
  };

  const onTimezoneChange = (e: ChangeEvent<HTMLSelectElement>) => {
    onChange(parsed?.minutes, parsed?.hours, e.target.value);
  };

  return (
    <SimpleGrid cols={2} mt={10}>
      <TimeField
        inputProps={{
          size: 'xs',
          error: !!error,
          disabled,
        }}
        timefieldProps={{
          label: 'At',
          onChange: onTimeChange,
          value: new Time(parsed?.hours, parsed?.minutes),
        }}
      />

      <Timezone
        name="timezone"
        value={parsed?.timezone}
        onChange={onTimezoneChange}
        disabled={disabled}
        size="xs"
        error={!!error}
        label="Timezone"
      />
    </SimpleGrid>
  );
};

export default At;
