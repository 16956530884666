import { useRef } from 'react';
import {
  ActionIcon, Group, Input, Popover, Stack,
} from '@mantine/core';
import { AriaDateRangePickerProps, DateValue, useDateRangePicker } from 'react-aria';
import { useDateRangePickerState } from 'react-stately';

import RangeCalendar from './RangeCalendar';
import DateField from './DateField';

import CalendarIcon from '@/assets/icons/content/calendar.svg';
import RightArrow from '@/assets/icons/content/right-arrow.svg';
import WarningIcon from '@/assets/icons/content/warning.svg';

import type { Presets, RangeValue } from './types';

import classes from './DatePicker.module.css';

interface DatePickerProps extends Omit<AriaDateRangePickerProps<DateValue>, 'value'> {
  presets?: Presets;
  onChange: ((value: string | RangeValue) => void);
  value: string | RangeValue | null;
}

const DatePicker = ({
  presets,
  onChange,
  value,
  ...datePickerProps
}: DatePickerProps) => {
  const nullOrVal = typeof value === 'undefined' ? null : value;
  const rangeValue = typeof nullOrVal === 'string' ? presets?.[nullOrVal].rangeDateValue() : nullOrVal;
  const ref = useRef(null);
  const state = useDateRangePickerState({ onChange, value: rangeValue });

  const {
    startFieldProps,
    endFieldProps,
    calendarProps,
    errorMessageProps,
  } = useDateRangePicker({ onChange, value: rangeValue, ...datePickerProps }, state, ref);

  return (
    <Popover
      classNames={{ dropdown: classes.datePickerDropdown }}
      opened={state.isOpen}
      onChange={state.setOpen}
      position="bottom-start"
    >
      <Popover.Target>
        <Stack gap={5}>
          <Input component="div" styles={{ input: { height: 'auto' } }} error={state.isInvalid}>
            <Group align="center" justify="space-between" wrap="nowrap">
              <Group gap={5}>
                <DateField {...startFieldProps} />
                <RightArrow />
                <DateField {...endFieldProps} />
              </Group>
              <ActionIcon variant="transparent" onClick={() => state.setOpen(!state.isOpen)}>
                <CalendarIcon />
              </ActionIcon>
            </Group>
          </Input>

          {state.isInvalid && (
            <span {...errorMessageProps} className={classes.datePickerError}>
              <WarningIcon />
              Start date must be before end date.
            </span>
          )}
        </Stack>
      </Popover.Target>

      <Popover.Dropdown>
        <RangeCalendar
          rangeCalendarProps={calendarProps}
          presets={presets}
          onChange={onChange}
        />
      </Popover.Dropdown>
    </Popover>
  );
};

export default DatePicker;
