// Conditions
type Operator = '>=';

export interface Condition {
  type: string;
  operator: Operator;
  value: string;
}

// Schedule
export const FREQUENCY = {
  INTERVAL: 'By Interval',
  DAILY: 'Daily',
  WEEKLY: 'Weekly',
  MONTHLY: 'Monthly',
} as const;

export type FrequencyNames = typeof FREQUENCY[keyof typeof FREQUENCY];

// Actions
type EmailAction = {
  email: string;
  subject: string;
};

type ActionType = 'notify_email';

export interface Action {
  type: ActionType;
  values: EmailAction;
}

export const NOTIFICATION_STATUS = {
  ENABLED: 'enabled',
  DISABLED: 'disabled',
} as const;

export type NotificationStatus = typeof NOTIFICATION_STATUS[keyof typeof NOTIFICATION_STATUS];

export interface Notification {
  id: number;
  name: string;
  status: NotificationStatus;
  query_type: string;
  query: string;
  schedules: Array<string>;
  conditions: Array<Condition & { id: number }>;
  actions: Array<Action & { id: number }>;
  count_triggered: number;
  last_triggered: string;
  created_at: string;
  updated_at: string;
}
