const TRANSACTION_STATUSES = {
  FEEDBACK: 'Feedback',
  REJECTED: 'Rejected',
  CLOSED: 'Closed',
  RESOLVED: 'Resolved',
  APPROVED: 'Approved for Production',
} as const;

type TransactionStatus = typeof TRANSACTION_STATUSES[keyof typeof TRANSACTION_STATUSES];

export interface Transactions {
  issue_id: number;
  tracker_id: number;
  reference: string;
  created_on: string;
  referer: string;
  status: TransactionStatus;
  credit: number;
  debit: number;
}
