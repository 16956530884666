import { forwardRef } from 'react';
import { Grid, GridColProps, GridProps } from '@mantine/core';

import classes from './Grid.module.css';

interface MercuryGridProps extends GridProps {
  removePadding?: boolean
}

export const MercuryGrid = ({ columns, removePadding, children }: MercuryGridProps) => (
  <Grid
    columns={columns}
    gutter={25}
    classNames={{
      root: `
      ${classes.root}
      mercury__grid
      ${removePadding ? classes.removePadding : ''}
      `,
    }}
  >
    {children}
  </Grid>
);

interface MercuryColProps extends GridColProps {
  transparent?: boolean
  fullWidth?: boolean
}

export const MercuryCol = forwardRef((
  {
    children,
    transparent,
    fullWidth,
    ...colProps
  }: MercuryColProps,
  ref: any,
) => (
  <Grid.Col {...colProps} ref={ref}>
    <div className={
      `${classes.col}
      ${transparent ? classes.transparent : ''}
      ${fullWidth ? classes.fullWidth : ''}
      `
    }
    >
      {children}
    </div>
  </Grid.Col>
));
