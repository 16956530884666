import { useUncontrolled } from '@mantine/hooks';

import { useCreateNotification, useEditNotification } from './services/notification';
import { useQueryActions } from '@/hooks/useQueryActions';
import { settingsKeys } from '@/features/usersettings/services/keys';
import { useToast } from '@/hooks/useToast';

import type { NotificationSubmit } from './types';

export const useNotificationSystem = (id?: number) => {
  const { mutate: onCreateMutation, isPending: isCreating } = useCreateNotification();
  const { mutate: onEditNotification, isPending: isEditing } = useEditNotification(id);
  const { invalidateQuery } = useQueryActions();
  const { createToast } = useToast();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const [_opened, setOpened] = useUncontrolled({
    finalValue: false,
  });

  const onToggle = () => {
    if (_opened) {
      setOpened(false);
    } else {
      setOpened(true);
    }
  };

  const onSubmit: NotificationSubmit = (data) => {
    const cb = id ? onEditNotification : onCreateMutation;

    cb({
      ...data,
      schedules: [data.schedules],
    }, {
      onError: (error) => {
        createToast(error.message, 'error');
      },
      onSuccess: () => {
        invalidateQuery(settingsKeys.notificationRules());
        createToast(
          `${id ? 'Notification Rule updated' : 'Notification Rule created'} successfully`,
          'success',
          { to: 'settings?tab=notificationrules', text: 'See it in your settings' },
        );
        setOpened(false);
      },
    });
  };

  return {
    onToggle,
    opened: _opened,
    onSubmit,
    isSubmitting: isCreating || isEditing,
  };
};
