import { Box, Group, Input, InputWrapperProps } from '@mantine/core';
import { FieldValues } from 'react-hook-form';

import MercuryButton from '../../button/Button';
import List from '../../list/List';

import { formatBytes } from '@/utils';

import type { BaseElement } from '@/types/form';

import classes from '../Form.module.css';

interface MercuryFileProps<TFormValues extends FieldValues> extends
  Omit<InputWrapperProps, 'name'>,
  BaseElement<TFormValues> {
  extension?: string
}

const MercuryFile = <TFormValues extends FieldValues>({
  register,
  name,
  formOptions,
  extension,
  watch,
  displayError,
  resetField,
  error,
  ...inputProps
}: MercuryFileProps<TFormValues>) => {
  const value = watch && watch(name);
  const isSet = value && value.length > 0;

  const onClear = () => resetField?.(name);

  return (
    <Box>
      <Input.Label component="span">{inputProps.label}</Input.Label>

      <Input.Error>{error}</Input.Error>

      <Input
        {...register?.(name, formOptions)}
        id={name}
        type="file"
        display="none"
        multiple
      />

      <Group gap={15} mt={3} mb={inputProps.description ? 5 : 0}>
        <MercuryButton>
          <Input.Label htmlFor={name}>
            Choose File {extension ? `(${extension})` : ''}
          </Input.Label>
        </MercuryButton>

        {isSet && <button className={classes.clearFiles} type="button" onClick={onClear}>Clear</button>}
      </Group>

      {
        isSet && (
          <List
            items={[...(value as any[])]}
            mt={15}
            gap={5}
            renderItem={(file) => (
              <li className={classes.file}>
                - {file.name} - <b>{formatBytes(file.size)}</b>
              </li>
            )}
          />
        )
      }

      {inputProps.description && (
        <Input.Description mt={5}>{inputProps.description}</Input.Description>
      )}
    </Box>
  );
};

export default MercuryFile;
