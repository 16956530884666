import { Tooltip, UnstyledButton } from '@mantine/core';

import classes from './DatePicker.module.css';

import type { Preset } from './types';

interface PresetProps {
  setNewRange: (value: string) => void;
  preset: Preset;
}

const PresetComp = ({ preset, setNewRange }: PresetProps) => (
  <Tooltip label={preset.preview as string} disabled={!preset.preview}>
    <UnstyledButton onClick={() => setNewRange(preset.value)} classNames={{ root: classes.datePickerPreset }}>
      {preset.label}
    </UnstyledButton>
  </Tooltip>
);

export default PresetComp;
