import { Group } from '@mantine/core';

import MetaContainer from './MetaContainer';
import MetaSection from './MetaSection';
import MetaPlaceholder from './MetaPlaceholder';
import Button from '@/components/content/button/Button';
import DebouncedInput from '@/components/content/form/components/DebouncedInput';

import type { FiltersMeta } from '@/types/meta';
import type { MetaCategories } from '../types';
import type { MetaStore } from '@/hooks/useMetaStore';

import SearchIcon from '@/assets/icons/content/search.svg';
import classes from './Meta.module.css';

interface MetaWrapperProps {
  metaData?: { meta: MetaCategories | FiltersMeta; defaultValues?: Record<string, any> | null; }
  metaStore: MetaStore;
  hideSearch?: boolean;
  hideClear?: boolean;
}

const MetaWrapper = ({
  metaData,
  metaStore,
  hideSearch,
  hideClear,
}: MetaWrapperProps) => {
  if (!metaData) {
    return <MetaPlaceholder />;
  }

  const renderMeta = metaData.meta;
  const isRawMeta = Array.isArray(renderMeta);

  return (
    <div>
      {!hideSearch && !hideClear && (
        <Group>
          {!hideSearch && (
            <DebouncedInput
              setValue={(search) => metaStore.search.set({ search })}
              value={metaStore.search.state.search}
              placeholder="Search"
              classNames={{
                wrapper: classes.search,
                root: classes.search,
              }}
              rightSection={<SearchIcon width={20} height={20} />}
            />
          )}

          {isRawMeta && !hideClear && (
            <Button onClick={metaStore.clearAll} color="var(--quo-power)">
              Clear Filters
            </Button>
          )}
        </Group>
      )}

      <div className={classes.metaRoot}>
        {isRawMeta ? (
          <MetaContainer meta={renderMeta} store={metaStore} />
        ) : (
          <MetaSection
            metaCategories={renderMeta}
            store={metaStore}
          />
        )}
      </div>
    </div>
  );
};

export default MetaWrapper;
