import { Text } from '@mantine/core';
import { superstructResolver } from '@hookform/resolvers/superstruct';
import { object as ST, size, string } from 'superstruct';

// Components
import Form from '@/components/content/form/Form';
import MercuryTextInput from '@/components/content/form/components/TextInput';
import MercuryTitle from '@/components/content/title/Title';

// Hooks
import { useQRCode } from '../../hooks/useQrCode';

// Misc
import { required } from '@/utils/inputValidation';
import { TFAData } from '../../hooks/useTfa';

import type { OnSubmitFunc } from '@/types/form';
import type { TFAFormFields } from '../../types';
import { TFAFormLabels } from '../../types';

interface TwoFAModalProps {
  data?: TFAData
  enabled: boolean;
  tfaAction: OnSubmitFunc<TFAFormFields>;
  disableAction: boolean;
}

const TwoFAModal = ({ data, enabled, tfaAction, disableAction }: TwoFAModalProps) => {
  const QRCode = useQRCode();

  const schema = ST({
    code: required(size(string(), 6, 6), 'Code is required'),
  });

  return (
    <>
      {!enabled && (
        <div>
          <Text>Begin by installing a Two-Factor Authentication app on your device:</Text>

          <MercuryTitle order={3} size="h4" mt={15}>
            Option 1: Type this secret into your app
          </MercuryTitle>

          <Text>
            <b>Secret: </b>{data?.secret}
          </Text>

          <MercuryTitle order={3} size="h4" mt={15}>
            Option 2: Scan the QR code with your app
          </MercuryTitle>

          <QRCode text={data?.uri || ''} options={{ margin: 1 }} />
        </div>
      )}

      <Form<TFAFormFields>
        onSubmit={tfaAction}
        options={{ resolver: superstructResolver(schema) }}
        submitButton={{
          text: 'Submit',
          disabled: disableAction,
        }}
        name="tfa-form"
        fields={TFAFormLabels}
      >
        {({ register, formState: { errors } }) => (
          <MercuryTextInput<TFAFormFields>
            register={register}
            type="text"
            name="code"
            placeholder="Type generated code here"
            label={TFAFormLabels.code}
            error={errors?.code?.message}
            autoComplete="off"
            required
          />
        )}
      </Form>
    </>
  );
};

export default TwoFAModal;
