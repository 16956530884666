/* eslint-disable react/no-array-index-key */
import { type PropsWithChildren } from 'react';
import { Box, SimpleGrid } from '@mantine/core';

import Skeleton from '../placeholder/Skeleton';

interface TablePlaceholderProps {
  isPending?: boolean;
}

const TablePlaceholder = ({
  children,
  isPending = true,
}: PropsWithChildren<TablePlaceholderProps>) => {
  const height = 31;

  return !isPending ? children : (
    <Box mt={25}>
      <SimpleGrid cols={4}>
        <Skeleton n={4} height={height} id="table-header-placeholder" />
      </SimpleGrid>

      <Box mt={10}>
        <Skeleton
          n={10}
          id="table-row"
          height={31}
          renderItem={(item) => (
            <SimpleGrid cols={4} mt={10}>
              {item}
              {item}
              {item}
              {item}
            </SimpleGrid>
          )}
        />
      </Box>
    </Box>
  );
};

export default TablePlaceholder;
