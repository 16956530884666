import { Group } from '@mantine/core';
import type { AriaDateFieldProps, DateValue } from 'react-aria';

import { DateSegment } from '../common/Segment';

import { useDatePicker } from './useDateField';

const DateField = ({ label, ...props }: AriaDateFieldProps<DateValue>) => {
  const { labelProps, fieldProps, ref, segments, state } = useDatePicker(props);

  return (
    <>
      {label && <span {...labelProps}>{label}</span>}
      <Group {...fieldProps} ref={ref} gap={0} wrap="nowrap">
        {segments.map((segment, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <DateSegment key={`${props['aria-labelledby']}-segment-${i}`} segment={segment} state={state} />
        ))}
      </Group>
    </>
  );
};

export default DateField;
