import { type FormEvent, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, BoxProps, Group } from '@mantine/core';
import {
  type UseFormProps, type FieldValues, type Path, type UseFormReturn, useForm,
} from 'react-hook-form';

import Button from '../button/Button';
import Message from '../message/Message';

import { useMessage } from '@/hooks/useMessage';
import { getEntries } from '@/utils';

import type {
  CancelFormButton, Formbutton, GeneralErrorFunc, OnSubmitFunc, SuccessMessageFunc,
} from '@/types/form';

import classes from './Form.module.css';

interface FormProps<TFormValues extends FieldValues> {
  options?: UseFormProps<TFormValues>;
  onSubmit: OnSubmitFunc<TFormValues>;
  name?: string;
  focusOn?: Path<TFormValues>;
  children: (methods: UseFormReturn<TFormValues>) => React.ReactNode;
  variant?: 'small' | 'regular';
  backButton?: boolean;
  submitButton?: Partial<Formbutton>;
  cancelButton?: CancelFormButton;
  fields?: Record<string, string>;
  styles?: BoxProps;
  disableSubmitOnInvalid?: boolean;
}

const Form = <TFormValues extends FieldValues = FieldValues>({
  children,
  options,
  onSubmit,
  name,
  focusOn,
  backButton,
  submitButton,
  cancelButton,
  fields,
  styles,
  variant,
  disableSubmitOnInvalid,
}: FormProps<TFormValues>) => {
  const methods = useForm<TFormValues>(options);
  const navigate = useNavigate();
  const { createMessage, removeMessage, message, messageConfig } = useMessage();

  const buildErrorMessage = (errorMessage: string, details: Array<any>) => `
      <p>${errorMessage}</p>

      <ul>
      ${details.map(([key, val]) => `<li><b>${fields && fields[key]}:</b> ${val}</li>`).join()}
      </ul>
    `;

  const setSuccess: SuccessMessageFunc = (successMessage, reset = true, config?) => {
    createMessage(successMessage, { type: 'success' }, config);

    if (reset) methods.reset();
  };

  const setGeneralError: GeneralErrorFunc = (error) => {
    const isString = typeof error === 'string';
    let errorMessage = isString ? error : error.message;

    if (!isString && error.details && fields) {
      const details = getEntries(error.details ?? {}).map(([key, val]) => {
        const field = key.split('.');

        return [field.length > 1 ? field[1] : field[0], val];
      });

      errorMessage = buildErrorMessage(error.message, details);

      details.forEach(([field, val]) => {
        methods.setError(field, { type: 'custom', message: val });
      });
    }

    createMessage(
      errorMessage,
      { type: 'error' },
      { closeButton: true, customHtml: !isString && typeof error.details !== 'undefined' },
    );
  };

  const onSubmitMiddleWare = async (event: FormEvent<HTMLFormElement>) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    return methods.handleSubmit(async (data) => {
      onSubmit(data, setGeneralError, setSuccess, methods.reset);
    })(event);
  };

  useEffect(() => {
    if (focusOn) {
      methods.setFocus(focusOn);
    }
  }, [focusOn]);

  const navigateBack = () => {
    navigate(-1);
  };

  const disableSubmit = disableSubmitOnInvalid && methods.formState.isValid === false ? true : submitButton?.disabled;

  return (
    <>
      <Message message={message} removeMessage={removeMessage} {...messageConfig} />

      <Box
        component="form"
        name={name}
        onSubmit={onSubmitMiddleWare}
        className={`${classes.form} ${variant === 'small' ? classes.small : ''}`}
        {...styles}
      >
        {children(methods)}

        {(cancelButton || submitButton || backButton) && (
          <Group mt={25} justify="space-between" align="center" classNames={{ root: classes.buttonGroup }}>
            {cancelButton && (
              <Button
                onClick={cancelButton.onCancel}
                disabled={cancelButton.disabled}
                variant="outline"
              >
                {cancelButton.text || 'Cancel'}
              </Button>
            )}

            {backButton && (
              <Button
                onClick={navigateBack}
                variant="outline"
              >
                Back
              </Button>
            )}

            {submitButton && (
              <Button
                type="submit"
                disabled={disableSubmit}
                color={submitButton.background}
                loading={submitButton.loading}
              >
                {submitButton.text ?? 'Submit'}
              </Button>
            )}
          </Group>
        )}
      </Box>
    </>
  );
};

export default Form;
