import { FREQUENCY } from '../../../types';

import * as Interval from './interval';
import * as Daily from './daily';
import * as Weekly from './weekly';
import * as Monthly from './monthly';

export * from './interval';
export * from './daily';
export * from './weekly';
export * from './monthly';

export const parseInitialSchedule = (val: string) => {
  const interval = Interval.parseInterval(val);
  if (interval) return { ...interval, frequency: FREQUENCY.INTERVAL };

  const daily = Daily.parseDaily(val);
  if (daily) return { ...daily, frequency: FREQUENCY.DAILY };

  const weekly = Weekly.parseWeekly(val);
  if (weekly) return { ...weekly, frequency: FREQUENCY.WEEKLY };

  const monthly = Monthly.parseMonthly(val);
  if (monthly) return { ...monthly, frequency: FREQUENCY.MONTHLY };

  return null;
};
