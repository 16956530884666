import useUrlState, { type UrlStateActions } from './useUrlStore';

interface MetaStoreArguments {
  namespace?: string;
  initialFilters?: Record<string, any>;
  initialCustomMeta?: Record<string, any>;
  customMessages?: Record<string, string>;
  baseState?: Record<string, any>;
}

type Store = {
  state: Record<string, any>;
} & UrlStateActions;

export type MetaStore = {
  filters: Store;
  customMeta: Store;
  search: Store;
  clearAll: () => void;
  message?: string;
};

export const useMetaStore = ({
  namespace,
  initialFilters,
  initialCustomMeta,
  customMessages,
  baseState,
}: MetaStoreArguments): MetaStore => {
  const [
    filters,
    filterActions,
  ] = useUrlState(
    initialFilters,
    {
      namespace,
      nest: ['filter'],
      baseState,
    },
  );

  const [
    customMeta,
    customMetaActions,
  ] = useUrlState(
    initialCustomMeta,
    {
      namespace,
      nest: ['custom'],
      baseState,
    },
  );

  const [
    search,
    searchActions,
  ] = useUrlState(
    { search: '' },
    {
      namespace,
      nest: ['search'],
      baseState,
    },
  );

  const [
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    state,
    namespaceStateActions,
  ] = useUrlState(
    initialCustomMeta,
    { namespace, baseState },
  );

  const clearAll = () => {
    namespaceStateActions.clear(
      ['filter', 'custom', 'search'],
    );
  };

  const getMessage = () => {
    const metaInUse = [search.search && 'search', ...Object.keys({ ...filters, ...customMeta })].filter(Boolean);
    const defaultMessage = "Your filtering didn't bring any result";

    if (!metaInUse.length) return undefined;

    if (metaInUse.length > 1) return defaultMessage;

    return customMessages?.[metaInUse[0]] ?? defaultMessage;
  };

  const metaStore = {
    filters: { state: filters, ...filterActions },
    customMeta: { state: customMeta, ...customMetaActions },
    search: { state: search, ...searchActions },
    clearAll,
    message: getMessage(),
  };

  return metaStore;
};
