import { postMutation } from '@/lib/react-query/mutate';

const password = 'password';

export const usePasswordChangeMutation = () => postMutation({
  endpoint: password,
  method: 'PUT',
  requestOptions: {
    error: `
    There was an error processing the password change, might be that your password is incorrect. Please try again
    `,
  },
});
