import { useMemo, useState } from 'react';
import {
  getPaginationRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  ColumnDef,
  TableOptions,
  OnChangeFn,
  RowSelectionState,
  getGroupedRowModel,
} from '@tanstack/react-table';

import { TableState, useTableStore } from './useTableStore';

export interface UseTableParams<TData> {
  columns: ColumnDef<TData, any>[];
  basic?: boolean;
  initialState?: Partial<TableState>;
  columnVisibility?: Record<string, boolean>;
  namespace?: string;
}

interface BaseReturn<TData> {
  setRowSelection: OnChangeFn<RowSelectionState>;
  state: TableState;
  baseTableConfig: Partial<Omit<TableOptions<TData>, 'data'>>;
}

export interface UseTableReturn<TData> extends Omit<BaseReturn<TData>, 'baseTableConfig'> {
  instanceConfiguration: BaseReturn<TData>['baseTableConfig'] & { columns: ColumnDef<TData, any>[] };
}

export const useTable = <TData>({
  columns,
  basic,
  initialState,
  columnVisibility = {},
  namespace,
}: UseTableParams<TData>): UseTableReturn<TData> => {
  const [rowSelection, setRowSelection] = useState({});

  const {
    tableState,
    tableUrlState,
    dispatchPagination,
    dispatchSorting,
    dispatchGrouping,
  } = useTableStore(initialState, namespace);

  const state = tableUrlState;

  const baseTableConfig = {
    state: { ...tableState, rowSelection },
    enableRowSelection: true, // enable row selection for all rows
    autoResetPageIndex: false,
    initialState: { columnVisibility },
    onRowSelectionChange: setRowSelection,
    getGroupedRowModel: getGroupedRowModel(),
    onPaginationChange: (setPagination: any) => {
      const pagination = setPagination(tableState.pagination);
      dispatchPagination(pagination.pageIndex * pagination.pageSize, pagination.pageSize);
    },
    onSortingChange: (setSorting: any) => {
      const sorting = setSorting(tableState.sorting);
      dispatchSorting(sorting[0].id, sorting[0].desc ? 'desc' : 'asc');
    },
    onGroupingChange: (setGrouping: any) => {
      const grouping = setGrouping(tableState.grouping);

      dispatchGrouping(grouping);
    },
  };

  const memoizedColumns = useMemo(() => columns, []);

  const clientTable = {
    autoResetPageIndex: false,
    enableColumnFilters: true,
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  };

  const serverTable = {
    manualPagination: true,
    manualSorting: true,
  };

  const tableConfig = basic ? clientTable : serverTable;

  const instanceConfiguration = {
    ...baseTableConfig,
    ...tableConfig,
    columns: memoizedColumns,
  };

  return {
    state,
    setRowSelection,
    instanceConfiguration,
  };
};
