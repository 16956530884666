/* eslint-disable @typescript-eslint/default-param-last */

/**
 * Converts weekly time into a cron expression.
 *
 * Example: Monday, Wednesday, and Fridays at 10:45 AM | PM
 * time: 10;
 *
 * Result: "45 10 * * 1,3,5 +0"
 * @param minutes - The minutes part of the time.
 * @param hours - The hours part of the time.
 * @param weekdays - The weekdays.
 * @param timezone - The timezone offset.
 * @returns The cron expression string.
 */
export const stringifyWeekly = (
  minutes: number | string = 0,
  hours: number | string = 0,
  weekdays: Array<string>,
  timezone: string = '+0',
) => {
  if (!weekdays.length) {
    throw new Error('Weekdays array cannot be empty');
  }

  return `${minutes} ${hours} * * ${weekdays.join(',')} ${timezone}`;
};

/**
 * Parses a cron expression into its weekly time components.
 *
 * @param value - The cron expression string.
 * @returns An object with the parsed components or null if invalid.
 */
export const parseWeekly = (value: string) => {
  if (!value) return null;

  const match = value.match(/^(\d+) (\d+) \* \* (\d+(?:,\d+)*)(?: (\+\d+))?$/);

  if (match) {
    return {
      minutes: +match[1],
      hours: +match[2],
      weekdays: match[3].split(','),
      timezone: match[4],
    };
  }

  return null;
};
