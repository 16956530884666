import { getEntries } from '@/utils';
import { IntervalNames, INTERVALS } from '../../../types';

/**
 * Parses interval into cron expression
 *
 * example: Every 10 minutes
 * value: 10;
 * interval: minutes
 *
 * result:  *\/10 * * * *
 * example: Every 2 hours
 * value: 2;
 * interval: hours
 * result: 0 *\/2 * * *"
 * @param value
 * @param interval
 */
export const stringifyInterval = (
  value: number | string,
  interval: IntervalNames,
) => {
  switch (interval) {
    case INTERVALS.MINUTES:
      return `*/${value} * * * *`;
    case INTERVALS.HOURS:
      return `0 */${value} * * *`;
    case INTERVALS.DAYS:
      return `0 0 */${value} * *`;
    default:
      return '';
  }
};

const intervalRegex = {
  [INTERVALS.MINUTES]: /^\*\/(\d+) \* \* \* \*(?: \+(\d+))?$/,
  [INTERVALS.HOURS]: /^0 \*\/(\d+) \* \* \*(?: \+(\d+))?$/,
  [INTERVALS.DAYS]: /^0 0 \*\/(\d+) \* \*(?: \+(\d+))?$/,
};

/**
 * Parse cron expression into interval with regex
 *
 * example: *\/10 * * * *
 * result: { value: 10, interval: 'minutes' }
 */
export const parseInterval = (value: string) => {
  if (!value) return null;

  const regexes = getEntries(intervalRegex);

  for (let i = 0; i < regexes.length; i += 1) {
    const [key, regex] = regexes[i];
    const match = value.match(regex);

    if (match) {
      return {
        value: +match[1],
        frequency: key,
      };
    }
  }

  return null;
};
