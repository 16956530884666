import { createColumnHelper } from '@tanstack/react-table';

import SubscriptionLink from './SubscriptionLink';

import { formatIsoDate } from '@/utils/toDate';

import type { Transactions } from '../../types';

const columnHelper = createColumnHelper<Transactions>();

export const subscriptionsTable = [
  columnHelper.accessor('issue_id', {
    header: 'ID',
    size: 100,
    cell: ({ getValue, row: { original: { tracker_id } } }) => (
      <SubscriptionLink id={getValue()} trackerId={tracker_id} />
    ),
    meta: {
      alignment: 'numeric',
    },
  }),
  columnHelper.accessor('reference', {
    size: 350,
  }),
  columnHelper.accessor('created_on', {
    size: 150,
    cell: ({ getValue }) => formatIsoDate(getValue(), true),
    header: 'Date',
  }),
  columnHelper.accessor('referer', {
    size: 150,
  }),
  columnHelper.accessor('status', {
    size: 150,
  }),
  columnHelper.accessor((data) => data.credit + data.debit, {
    size: 200,
    header: 'Change in credits',
    meta: {
      alignment: 'numeric',
    },
  }),
];
