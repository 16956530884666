import { useRef } from 'react';
import { useDateSegment } from 'react-aria';
import type { DateFieldState, DateSegment as TDateSegment } from 'react-stately';

interface DateSegmentProps {
  segment: TDateSegment;
  state: DateFieldState;
}

export const DateSegment = ({ segment, state }: DateSegmentProps) => {
  const ref = useRef(null);
  const { segmentProps } = useDateSegment(segment, state, ref);

  return (
    <div {...segmentProps} ref={ref}>
      {segment.text}
    </div>
  );
};
