import { upperFirst } from '@mantine/hooks';

import type { FiltersMeta } from '@/types/meta';
import type { MetaCategories } from '../types';

function missingCategories(data: FiltersMeta) {
  return data.some((filter) => !filter.frontend_category);
}

export const divideByCategory = (meta: FiltersMeta) => {
  if (missingCategories(meta)) {
    return meta.map((item) => ({
      ...item,
      choices: item.choices?.map((choice) => ({ ...choice, parent_id: choice.parent_cat || choice.parent_id })),
    }));
  }

  const divided = meta.reduce((accu, curr) => {
    const { frontend_category: frontendCategory, ...rest } = curr;

    if (!frontendCategory) {
      return accu;
    }

    if (!accu[frontendCategory]) {
      accu[frontendCategory] = {
        name: frontendCategory,
        label: upperFirst(frontendCategory.split('_')[0]),
        meta: [],
      };
    }

    accu[frontendCategory].meta.push(rest);

    return accu;
  }, {} as MetaCategories);

  return divided;
};
