import { Group } from '@mantine/core';

import { MercuryCol, MercuryGrid } from '@/components/grid/Grid';
import MercuryTabs from '@/components/content/tabs/Tabs';
import ProfileInfo from '../components/profileinfo/ProfileInfo';
import SubscriptionsInfo from '../components/subscriptions/SubscriptionsInfo';
import MercuryTitle from '@/components/content/title/Title';
import NotificationRules from '../components/notificationrules/NotificationRules';
import ContactSupport from '@/client/features/mailboxes/ContactSupport';

import { useMercuryPermissions } from '@/hooks/useMercuryPermissions';
import { FEATURES } from '@/constants/permissions';
import { useMediaQuery } from '@mantine/hooks';

const UserSettings = () => {
  const canCreateNotifications = useMercuryPermissions().hasAccess(FEATURES.notifications);
  const matches = useMediaQuery('(min-width: 62em)');

  const tabs = [
    {
      tab: 'My Organisation',
      val: 'organisation',
    },
    {
      tab: 'My Profile',
      val: 'profile',
    },
    {
      tab: 'My Notification Rules',
      val: 'notificationrules',
      hide: !canCreateNotifications,
    },
  ];

  return (
    <MercuryGrid>
      <MercuryCol transparent>
        <Group align="center" justify="space-between">
          <MercuryTitle order={1}>
            User Settings
          </MercuryTitle>

          <ContactSupport />
        </Group>
      </MercuryCol>

      <MercuryCol transparent>
        <MercuryTabs tabs={tabs} id="usersettings" saveTabInUrl renderSelect={!matches}>
          <SubscriptionsInfo />
          <ProfileInfo />
          <NotificationRules />
        </MercuryTabs>
      </MercuryCol>
    </MercuryGrid>
  );
};

export default UserSettings;
