import { Text, Title, TitleProps, createPolymorphicComponent } from '@mantine/core';

import classes from './Title.module.css';

interface MercuryTitleProps extends TitleProps {
  subtitle?: string;
}

const MercuryTitle = createPolymorphicComponent<'h1', MercuryTitleProps>(
  ({ children, subtitle, ...titleProps }: MercuryTitleProps) => (
    <Title {...titleProps} classNames={{ root: classes.title }}>
      {children}

      {subtitle && <Text span size="xs">{subtitle}</Text>}
    </Title>
  ),
);

export default MercuryTitle;
