import { createColumnHelper } from '@tanstack/react-table';

import Actions from './Actions';
import { Notification } from '@/client/features/notificationsystem/types';
import { toDateTime } from '@/utils/toDate';

const columnHelper = createColumnHelper<Notification>();

export const notificationRulesTable = [
  columnHelper.accessor('name', {
    header: 'Rule Name',
  }),
  columnHelper.accessor('created_at', {
    header: 'Created Date',
    cell: ({ getValue }) => toDateTime(getValue()),
  }),
  columnHelper.accessor('updated_at', {
    header: 'Last Updated',
    cell: ({ getValue }) => toDateTime(getValue()),
  }),
  columnHelper.accessor('last_triggered', {
    header: 'Last Notification Sent',
    size: 250,
    cell: ({ getValue }) => toDateTime(getValue()),
  }),
  columnHelper.display({
    id: 'actions',
    header: 'Actions',
    cell: ({ row }) => (
      <Actions rule={row.original} />
    ),
    meta: {
      alignment: 'center',
    },
  }),
];
