/**
 * Parses daily time into cron expression
 *
 * example: Every day at 10:45 AM | PM
 * time: 10;
 *
 * result: "45 10 * * *"
 * @param value
 * @param interval
 */
export const stringifyDaily = (
  minutes: number | string = 0,
  hours: number | string = 0,
  timezone: string = '+0',
) => (
  `${minutes} ${hours} * * * ${timezone}`
);

export const parseDaily = (value: string) => {
  if (!value) return null;

  const match = value.match(/^(\d+) (\d+) \* \* \*(?: (\+\d+))?$/);

  if (match) {
    return {
      minutes: +match[1],
      hours: +match[2],
      timezone: match[3],
    };
  }

  return null;
};
