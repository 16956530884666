import type { ReactNode } from 'react';
import { Group } from '@mantine/core';
import type { Table } from '@tanstack/react-table';

import DebouncedInput from '../form/components/DebouncedInput';

type ToolbarProps<TTableData> = {
  tableInstance: Table<TTableData>;
  hideSearch?: boolean;
  customActions?: ReactNode;
};

const Toolbar = <TTableData extends unknown>({
  tableInstance,
  customActions,
  hideSearch,
}: ToolbarProps<TTableData>) => (
  <>
    {(!hideSearch || customActions) && (
      <Group gap={15} justify="space-between">
        {!hideSearch && (
          <DebouncedInput
            w="100%"
            placeholder="Search..."
            role="searchbox"
            value={tableInstance.getState().globalFilter}
            setValue={(value) => tableInstance.setGlobalFilter(value)}
          />
        )}

        {customActions}
      </Group>
    )}
  </>
  );

export default Toolbar;
