import { useCallback } from 'react';

import DatePicker from '@/components/content/date/datepicker/DatePicker';
import { defaultPresets } from '@/components/content/date/datepicker/presets';

import type { RangeValue } from '@/components/content/date/datepicker/types';
import type { FilterProps } from '../types';
import type { TDate } from '@/types/meta';

import { parseRangeDate } from '@/utils/parseRangeDate';

const DateRange = ({ filterMeta, filter, setFilter }: FilterProps<TDate, string>) => {
  const value = parseRangeDate(filter);

  const onChange = useCallback((date: string | RangeValue) => {
    setFilter({
      [filterMeta.name]: typeof date === 'string' ? date : `${date.start.toString()}/${date.end.toString()}`,
    });
  }, []);

  return (
    <DatePicker
      onChange={onChange}
      value={value}
      aria-label={filterMeta.label}
      presets={defaultPresets}
    />
  );
};

export default DateRange;
