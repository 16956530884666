import { ActionIcon, Group, Tooltip } from '@mantine/core';

import NotificationSystem from '@/client/features/notificationsystem/NotificationSystem';

import { useDeleteNotification,
  useEditNotification } from '@/client/features/notificationsystem/services/notification';
import { useConfirmation } from '@/hooks/useConfirmation';
import { useQueryActions } from '@/hooks/useQueryActions';
import { settingsKeys } from '../../services/keys';

import { NOTIFICATION_STATUS, type Notification } from '@/client/features/notificationsystem/types';

import AcknowledgeIcon from '@/assets/icons/content/acknowledge.svg';
import ForbiddenIcon from '@/assets/icons/content/forbidden.svg';
import EditIcon from '@/assets/icons/content/edit.svg';
import TrashIcon from '@/assets/icons/content/trash.svg';

interface ActionsProps {
  rule: Notification;
}

const Actions = ({ rule }: ActionsProps) => {
  const { mutate: onToggleEnable, isPending: isTogglingEnabled } = useEditNotification(rule.id);
  const { mutate: onDeleteNotification, isPending: isDeleting } = useDeleteNotification(rule.id);
  const { getConfirmation } = useConfirmation();
  const { invalidateQuery } = useQueryActions();

  const onChange = () => {
    invalidateQuery(settingsKeys.notificationRules());
  };

  const toggleEnabled = () => {
    onToggleEnable({
      status: rule.status === NOTIFICATION_STATUS.ENABLED ? NOTIFICATION_STATUS.DISABLED : NOTIFICATION_STATUS.ENABLED,
    }, {
      onSuccess: onChange,
    });
  };

  const deleteNotification = async () => {
    const confirmed = await getConfirmation({
      title: 'Are you sure?',
      text: 'You are about to delete this rule',
      confirmText: 'Delete',
      confirmColor: 'var(--quo-pink)',
    });

    if (confirmed) {
      onDeleteNotification(
        {},
        {
          onSuccess: onChange,
        },
      );
    }
  };

  const status = rule.status === NOTIFICATION_STATUS.ENABLED ? 'Disable' : 'Enable';

  return (
    <Group gap={10} justify="center" align="center">
      <Tooltip label={`${status} Rule`}>
        <ActionIcon
          aria-label={`${status} Rule`}
          variant="subtle"
          onClick={toggleEnabled}
          loading={isTogglingEnabled}
          size={22}
        >
          {rule.status === NOTIFICATION_STATUS.DISABLED ? <AcknowledgeIcon /> : <ForbiddenIcon />}
        </ActionIcon>
      </Tooltip>

      <NotificationSystem
        data={{
          name: rule.name,
          query: rule.query,
          actions: rule.actions,
          conditions: rule.conditions,
          schedules: rule.schedules[0],
        }}
        id={rule.id}
      >
        <Tooltip label="Edit Rule">
          <ActionIcon
            variant="subtle"
            aria-label="Edit Rule"
            size={22}
          >
            <EditIcon />
          </ActionIcon>
        </Tooltip>
      </NotificationSystem>

      <Tooltip label="Delete Rule">
        <ActionIcon
          aria-label="Delete Rule"
          variant="subtle"
          color="var(--quo-pink)"
          loading={isDeleting}
          onClick={deleteNotification}
          size={22}
        >
          <TrashIcon />
        </ActionIcon>
      </Tooltip>
    </Group>
  );
};

export default Actions;
