/* eslint-disable @typescript-eslint/default-param-last */

/**
 * Parses weekly time into cron expression
 *
 * example: Every month on the 1st and 2nd day at 10:45 AM | PM
 * time: 10;
 * days: ['1', '2']
 *
* Result: "45 10 1,2 * * +0"
 * @param minutes - The minutes part of the time.
 * @param hours - The hours part of the time.
 * @param days - The days of the month.
 * @param timezone - The timezone offset.
 * @returns The cron expression string.
 */
export const stringifyMonthly = (
  minutes: number | string = 0,
  hours: number | string = 0,
  days: Array<string | number>,
  timezone: string = '+0',
) => {
  if (!days.length) {
    throw new Error('Days array cannot be empty');
  }

  return `${minutes} ${hours} ${days.join(',')} * * ${timezone ?? '+0'}`;
};

/**
 * Parses a cron expression into its monthly time components.
 *
 * @param value - The cron expression string.
 * @returns An object with the parsed components or null if invalid.
 */
export const parseMonthly = (value: string) => {
  if (!value) return null;

  const match = value.match(/^(\d+) (\d+) (\d+(?:,\d+)*) \* \*(?: (\+\d+))?$/);

  if (match) {
    return {
      minutes: +match[1],
      hours: +match[2],
      days: match[3].split(','),
      timezone: match[4],
    };
  }

  return null;
};
