import { type ChangeEvent, useEffect, useMemo, useState } from 'react';
import { TextInput, type TextInputProps } from '@mantine/core';

import { debounce } from '@/utils/debounce';

interface DebouncedInputProps extends TextInputProps {
  debounceTime?: number;
  setValue: (value: string) => void;
}

const DebouncedInput = ({
  value,
  setValue,
  debounceTime = 500,
  ...inputProps
}: DebouncedInputProps) => {
  const [debouncedValue, setDebouncedValue] = useState('');

  useEffect(() => { if (value !== debouncedValue) setDebouncedValue(value as string); }, [setDebouncedValue, value]);

  const eventHandler = (e: ChangeEvent<HTMLInputElement>) => setValue(e.target.value);
  const setValueCb = (e: ChangeEvent<HTMLInputElement>) => setDebouncedValue(e.target.value);
  const onChange = useMemo(() => debounce(eventHandler, setValueCb, debounceTime), []);

  return (
    <TextInput
      value={debouncedValue}
      onChange={onChange}
      {...inputProps}
    />
  );
};

export default DebouncedInput;
