import { RangeValue } from '@/components/content/date/datepicker/types';
import { parseDate } from '@internationalized/date';

export const parseRangeDate = (date: string): string | RangeValue | null => {
  if (!date) return null;

  if (date.startsWith('now')) {
    return date;
  }

  const [start, end] = date.split('/').map((d) => parseDate(d));

  return { start, end };
};
