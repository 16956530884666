import { useMemo, useState } from 'react';

import type { Item, MultiSelectBaseProps, NormalizedItem, Value } from './types';

interface ItemsStore {
  items: Array<Item>;
  listStore: {
    allValues: Array<Value>;
    byValue: Record<Value, Item>;
  };

}

const getItemsStore = (items: Array<NormalizedItem>) => {
  const itemsStore: ItemsStore = {
    /**
     * Flat list of selectable items
     */
    items: [],

    /**
     * Normalized store for all UI elements to be rendered
     */
    listStore: {
      allValues: [],
      byValue: {},
    },
  };

  const recursiveFlat = (recursiveList: Array<NormalizedItem>, root: Value | null, parent: Value | null) => {
    let itemRoot = root;

    recursiveList.forEach((item: NormalizedItem) => {
      const { value, label, children, depth } = item;
      const isRoot = !depth && typeof children !== 'undefined' && children.length > 0;
      const itemParent = value;

      if (isRoot) {
        itemRoot = value;
      }

      const newItem = {
        label: label ?? value,
        value,
        depth,
        children: children as Array<Item>,
        root,
        parent,
      };

      itemsStore.items.push(newItem);
      itemsStore.listStore.allValues.push(value);
      itemsStore.listStore.byValue[value] = newItem;

      if (children && children.length) {
        recursiveFlat(children, itemRoot, itemParent);
      }
    });
  };

  recursiveFlat(items, null, null);

  return itemsStore;
};

export const useItemStore = ({
  items,
  removeSelectedItems,
  value,
  forceCustomText,
}: Omit<MultiSelectBaseProps, 'setValue'>) => {
  const [search, setSearch] = useState('');
  const itemsStore = useMemo(() => getItemsStore(items), [forceCustomText]);

  const getItemByValue = (itemValue: Value) => itemsStore.listStore.byValue[itemValue];

  // recursive search function
  function findElementsIterative(data: Array<Item>, searchTerm: string): Array<Item> {
    const filtered = data.filter((item) => {
      if (`${item.label}`.toLowerCase().includes(searchTerm.toLowerCase())) {
        return true;
      }

      if (item.children) {
        return findElementsIterative(item.children, searchTerm).length > 0;
      }

      return false;
    });

    return filtered;
  }

  // filter items based on search and group them by parent
  const { groupedByParentItems } = useMemo(() => {
    const filteredBySearchTerm = findElementsIterative(itemsStore.items, search);
    const afterRemoveItems = removeSelectedItems
      ? filteredBySearchTerm.filter((it) => !value.some((val) => it.value === val.value))
      : filteredBySearchTerm;

    return {
      groupedByParentItems: Object.values(afterRemoveItems.reduce((accu, { children, ...item }) => {
        const root = item.root ?? item.value;

        if (!accu[root]) {
          accu[root] = [];
        }

        accu[root].push(item);

        return accu;
      }, {} as Record<Value, Array<Item>>)),
    };
  }, [search, value.length, forceCustomText]);

  // utility for looping through everything meant to render in the select
  const mapSelectList = (callback: (items: Array<Item>, index: number) => void): any => (
    groupedByParentItems.map((groupedItems, index) => callback(
      groupedItems.map((it) => (getItemByValue(it.value))),
      index,
    ))
  );

  return {
    itemsStore,
    getItemByValue,
    search,
    setSearch,
    mapSelectList,
    allSelected: !groupedByParentItems.length && search === '',
    // nothingFound: !visibleItems.length && search !== '',
    nothingFound: false,
  };
};
