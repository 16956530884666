import { superstructResolver } from '@hookform/resolvers/superstruct';
import { object as ST, string } from 'superstruct';

// Components
import Form from '@/components/content/form/Form';
import MercuryTextInput from '@/components/content/form/components/TextInput';

// Types
import type { OnSubmitFunc } from '@/types/form';
import { PasswordChangeFields, PasswordChangeLabels } from '../../types';

// Misc
import { password, required, match } from '@/utils/inputValidation';
import { PASSWORD_INFO } from '@/constants/text/english';

interface PasswordChangeModalProps {
  onSubmit: OnSubmitFunc<PasswordChangeFields>;
  isSubmitting: boolean;
}

const PasswordChangeModal = ({ onSubmit, isSubmitting }: PasswordChangeModalProps) => {
  const schema = ST({
    currentPassword: required(string(), 'Password is required'),
    newPassword: password('Password is not valid'),
    retypeNewPassword: match('newPassword', 'Passwords don\'t match'),
  });

  return (
    <Form<PasswordChangeFields>
      onSubmit={onSubmit}
      options={{ resolver: superstructResolver(schema) }}
      name="password-reset-form"
      submitButton={{
        text: 'Submit',
        disabled: isSubmitting,
      }}
      fields={PasswordChangeLabels}
    >
      {({ register, formState: { errors } }) => (
        <>
          <MercuryTextInput<PasswordChangeFields>
            register={register}
            type="password"
            name="currentPassword"
            label={PasswordChangeLabels.currentPassword}
            description="Please provide your current password"
            error={errors?.currentPassword?.message}
            required
          />

          <MercuryTextInput<PasswordChangeFields>
            register={register}
            type="password"
            name="newPassword"
            label={PasswordChangeLabels.newPassword}
            description={PASSWORD_INFO}
            error={errors?.newPassword?.message}
            autoComplete="new-password"
            displayError
            required
          />

          <MercuryTextInput<PasswordChangeFields>
            register={register}
            type="password"
            name="retypeNewPassword"
            label={PasswordChangeLabels.retypeNewPassword}
            error={errors?.retypeNewPassword?.message}
            autoComplete="new-password"
            displayError
            required
          />
        </>
      )}
    </Form>
  );
};

export default PasswordChangeModal;
