import {
  createContext, PropsWithChildren, useMemo, useRef, useState,
} from 'react';
import produce from 'immer';

import type { Toast, ToastLevel, ToastLink } from '@/components/content/toast/types';

type ToastContextValue = {
  toasts: Toast[];
  createToast: (message: string, level?: ToastLevel, link?: ToastLink) => number;
  removeToast: (id: number) => void;
  clear: () => void;
};

const DEFAULT_TOAST_DURATION = 5000;

export const ToastContext = createContext<ToastContextValue | null>(null);

export const ToastContextProvider = ({ children }: PropsWithChildren<{}>) => {
  const [toasts, setToasts] = useState<Array<Toast>>([]);
  const toastUid = useRef(0);

  const removeToast = (id: number) => setToasts((oldToasts) => oldToasts.filter((toast) => toast.id !== id));

  const createToast = (
    message: string,
    level: ToastLevel = 'success',
    link?: ToastLink,
  ) => {
    const id = toastUid.current;
    toastUid.current += 1;

    const timeout = setTimeout(() => removeToast(id), DEFAULT_TOAST_DURATION);

    setToasts(produce((oldToasts) => {
      const newToast: Toast = {
        id,
        message,
        level,
        clear: () => {
          clearTimeout(timeout);
          removeToast(id);
        },
        link,
      };

      oldToasts.push(newToast);
    }));

    return id;
  };

  const clear = () => setToasts([]);

  const contextValue = useMemo(
    () => ({ toasts, removeToast, createToast, clear }),
    [toasts, removeToast, createToast, clear],
  );

  return (
    <ToastContext.Provider value={contextValue}>
      {children}
    </ToastContext.Provider>
  );
};
