import type { ReactNode } from 'react';
import {
  Box, ElementProps, Input, InputProps, InputWrapperProps,
} from '@mantine/core';
import type { FieldValues } from 'react-hook-form';

import type { BaseElement } from '@/types/form';

import classes from '../Form.module.css';

interface MercuryTextInputProps<TFormValues extends FieldValues> extends
  InputProps,
  Omit<ElementProps<'input'>, 'name' | 'size'>,
  BaseElement<TFormValues> {
  label?: string;
  description?: string;
  withAsterisk?: boolean;
  link?: ReactNode;
  wrapperProps?: InputWrapperProps;
}

const MercuryTextInput = <TFormValues extends FieldValues>({
  register,
  name,
  formOptions,
  hidden,
  label,
  description,
  error,
  link,
  required,
  displayError,
  size,
  styles,
  wrapperProps,
  ...inputProps
}: MercuryTextInputProps<TFormValues>) => {
  const errorValue = () => {
    if (displayError) {
      return error;
    }

    return error ? true : null;
  };

  return (
    <Input.Wrapper
      label={label}
      error={errorValue()}
      description={description}
      withAsterisk={required}
      id={name}
      inputWrapperOrder={['label', 'error', 'input', 'description']}
      size={size}
      classNames={{ root: classes.inputWrapper }}
      {...wrapperProps}
    >
      {link && <Box className={classes.labelLink}>{link}</Box>}

      <Input
        aria-required={required}
        error={errorValue()}
        size={size}
        {...inputProps}
        {...register?.(name, formOptions)}
        classNames={{ input: classes.input }}
      />
    </Input.Wrapper>
  );
};

export default MercuryTextInput;
