import { useContext } from 'react';

import { ConfirmationContext } from '../components/content/confirmationdialog/ConfirmationContext';

import type { DialogParams } from '@/components/content/confirmationdialog/types';

export const useConfirmation = () => {
  const { openDialog } = useContext(ConfirmationContext);

  const getConfirmation = ({ ...options }: DialogParams) => new Promise((res) => {
    openDialog({ actionCallback: res, ...options });
  });

  return { getConfirmation };
};
