export type PasswordChangeFields = {
  currentPassword: string;
  newPassword: string;
  retypeNewPassword: string;
};

export const PasswordChangeLabels = {
  currentPassword: 'Current Password',
  newPassword: 'New Password',
  retypeNewPassword: 'Retype New Password',
} as const;

export type TFAFormFields = { code: string; };

export const TFAFormLabels = { code: 'Enter the code from your Two-Factor app' } as const;
