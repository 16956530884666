import { NativeSelect, type NativeSelectProps } from '@mantine/core';
import type { FieldValues } from 'react-hook-form';

import BaseInput from './BaseInput';

import type { BaseElement } from '@/types/form';

export interface MercuryNativeSelectProps<TFormValues extends FieldValues> extends
  Omit<NativeSelectProps, 'name'>,
  BaseElement<TFormValues> {
  placeholder?: string;
}

const MercuryNativeSelect = <TFormValues extends FieldValues>({
  register,
  name,
  formOptions,
  placeholder,
  data,
  ...inputProps
}: MercuryNativeSelectProps<TFormValues>) => {
  const options = placeholder && data ? [{ value: '', label: placeholder, disabled: true }, ...data] : data;

  return (
    <BaseInput
      {...register?.(name, formOptions)}
      {...inputProps}
      data={options}
      id={name}
      element={NativeSelect}
    />
  );
};

export default MercuryNativeSelect;
