import { NumberInput, NumberInputProps } from '@mantine/core';

import BaseInput from './BaseInput';

import type { BaseElement } from '@/types/form';
import type { FieldValues } from 'react-hook-form';

interface MercuryNumberInputProps<TFormValues extends FieldValues> extends
  Omit<NumberInputProps, 'name'>,
  BaseElement<TFormValues> { }

const MercuryNumberInput = <TFormValues extends FieldValues>({
  register,
  name,
  formOptions,
  ...inputProps
}: MercuryNumberInputProps<TFormValues>) => (
  <BaseInput
    element={NumberInput}
    id={name}
    clampBehavior="strict"
    allowNegative={false}
    {...register?.(name, formOptions)}
    {...inputProps}
  />
  );

export default MercuryNumberInput;
