export const unifiedApiKeys = {
  all: ['unified-api'],

  records: (endpoint: string, params?: Record<string, any>) => (
    [...unifiedApiKeys.all, endpoint, ...(params ? [params] : [])]
  ) as const,

  summary: (endpoint: string, params?: Record<string, any>) => (
    ['summary', ...unifiedApiKeys.records(endpoint, params)]
  ) as const,

  ticket: (endpoint: string, id: number) => (
    ['ticket', id, ...unifiedApiKeys.records(endpoint)]
  ) as const,

  attachments: (endpoint: string, id: number) => (
    ['attachments', ...unifiedApiKeys.ticket(endpoint, id)]
  ) as const,

  choices: (endpoint: string, choice: string) => (
    ['choices', choice, ...unifiedApiKeys.records(endpoint)]
  ) as const,
};
