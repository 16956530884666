import { cloneElement, type PropsWithChildren } from 'react';
import { isElement } from '@mantine/core';

import MercuryModal from '@/components/content/modal/Modal';
import NotificationSystemModal from './NotificationSystemModal';
import type { NotificationSystemFormFields } from './types';

import { useNotificationSystem } from './useNotificationSystem';

interface NotificationSystemProps {
  data: Partial<NotificationSystemFormFields>;
  id?: number;
}

const NotificationSystem = ({
  data,
  id,
  children,
}: PropsWithChildren<NotificationSystemProps>) => {
  const {
    onToggle,
    opened,
    onSubmit,
    isSubmitting,
  } = useNotificationSystem(id);

  if (!isElement(children)) {
    throw new Error(
      // eslint-disable-next-line max-len
      'NotificationSystem.Target component children should be an element or a component that accepts ref. Fragments, strings, numbers and other primitive values are not supported',
    );
  }

  return (
    <>
      {cloneElement(children, { onClick: onToggle })}

      <MercuryModal
        opened={opened}
        onClose={onToggle}
        title="Set Your Rule"
        size="xl"
        centered
      >
        <NotificationSystemModal
          onSubmit={onSubmit}
          isSubmitting={isSubmitting}
          closeModal={onToggle}
          data={data}
          submitText={id ? 'Update Rule' : 'Set Rule'}
        />
      </MercuryModal>
    </>
  );
};

export default NotificationSystem;
