import { deleteMutation, postMutation } from '@/lib/react-query/mutate';

import type { Notification, NotificationStatus, NotificationSystemFormFields } from '../types';
import type { Overwrite } from '@/types/general';

const URL = 'mercury/rules';

type Variables = Overwrite<NotificationSystemFormFields, { schedules?: Array<string>; status?: NotificationStatus }>;

export const useCreateNotification = () => (
  postMutation<Notification, Partial<Variables>>({
    endpoint: `${URL}/`,
  })
);

export const useEditNotification = (id?: number) => (
  postMutation<Notification, Partial<Variables>>({
    endpoint: `${URL}/${id}`,
    method: 'PATCH',
  })
);

export const useDeleteNotification = (id: number) => (
  deleteMutation({
    endpoint: `${URL}/${id}`,
  })
);

export const useNotificationPreview = () => postMutation<string, Variables>({
  endpoint: `${URL}/preview_email`,
});
