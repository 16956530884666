import { ReactNode, useCallback } from 'react';
import type { CoreInstance, Row as RowType } from '@tanstack/react-table';

// Components
import Row from './Row';

interface BodyProps<TTableData extends unknown> {
  instance: CoreInstance<TTableData>;
  subRowComponent?: ({ row }: { row: RowType<TTableData> }) => ReactNode;
  highlightRow?: (row: RowType<TTableData>) => boolean;
}

const Body = <TTableData extends unknown>({
  instance,
  subRowComponent: SubRowComponent,
  highlightRow,
}: BodyProps<TTableData>) => {
  const renderRowSubComponent = useCallback((row: RowType<TTableData>) => (
    SubRowComponent && <SubRowComponent row={row} />
  ), []);

  return (
    <tbody>
      {instance.getRowModel().rows.map((row) => (
        <Row
          key={row.id}
          row={row}
          highlightRow={highlightRow}
          isSelected={row.getIsSelected()}
          isExpanded={row.getIsExpanded()}
          renderRowSubComponent={renderRowSubComponent}
        />
      ))}
    </tbody>
  );
};

export default Body;
