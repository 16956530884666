/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-nested-ternary */
import { memo, ReactNode } from 'react';
import { flexRender, Row as RowType } from '@tanstack/react-table';

import classes from './Table.module.css';

interface RowProps<TTableData extends unknown> {
  isExpanded: boolean;
  isSelected: boolean;
  renderRowSubComponent: (row: RowType<TTableData>) => ReactNode;
  row: RowType<TTableData>;
  highlightRow?: (row: RowType<TTableData>) => boolean;
}

const RowComp = <TTableData extends unknown>({
  row,
  isExpanded,
  highlightRow,
  renderRowSubComponent,
}: RowProps<TTableData>) => {
  const subRowComponent = renderRowSubComponent(row);
  const isHighlighted = highlightRow?.(row);

  return (
    <>
      <tr className={`${classes.tr} ${isHighlighted ? classes.highlighted : ''}`}>
        {row.getVisibleCells().map((cell) => {
          const { column } = cell;
          const { alignment } = column.columnDef.meta || {};

          return (
            <td
              key={cell.id}
              className={`
                  ${classes.tableElement}
                  ${alignment === 'numeric' ? classes.numeric : ''}
                  ${alignment === 'center' ? classes.center : ''}
                `}
            >
              {
                cell.getIsAggregated() ? (
                  // If the cell is aggregated, use the Aggregated
                  // renderer for cell
                  flexRender(
                    cell.column.columnDef.aggregatedCell
                    ?? cell.column.columnDef.cell,
                    cell.getContext(),
                  )
                ) : cell.getIsPlaceholder() ? null : ( // For cells with repeated values, render null
                  // Otherwise, just render the regular cell
                  flexRender(
                    cell.column.columnDef.cell,
                    cell.getContext(),
                  )
                )
              }
            </td>
          );
        })}
      </tr>

      {isExpanded && subRowComponent ? (
        <tr className={classes.tr}>
          <td colSpan={row.getVisibleCells().length}>
            {subRowComponent}
          </td>
        </tr>
      ) : null}
    </>
  );
};

export default memo(RowComp) as <T>(props: RowProps<T>) => JSX.Element;
