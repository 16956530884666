import RouterLink from '@/components/content/link/RouterLink';

const SubscriptionLink = ({ id, trackerId }: { id: number; trackerId: number }) => {
  const rfiLink = '/servicerequests/alert/issue';
  const takedownLink = '/drp/takedown/issue';

  const link = trackerId === 12 ? takedownLink : rfiLink;

  if (!id) {
    return null;
  }

  return (
    <RouterLink to={`${link}/${id}`}>{id}</RouterLink>
  );
};

export default SubscriptionLink;
