import { Box, BoxProps } from '@mantine/core';
import type { PropsWithChildren } from 'react';

import classes from './FlexibleGridBox.module.css';

type GridCount = {
  S: number;
  M: number;
  L: number;
};

interface FlexibleGridBoxProps extends BoxProps {
  count: GridCount
}

const FlexibleGridBox = ({
  count,
  children,
  ...boxProps
}: PropsWithChildren<FlexibleGridBoxProps>) => (
  <Box
    className={classes.grid}
    style={{
      '--grid-column-count-S': count.S,
      '--grid-column-count-M': count.M,
      '--grid-column-count-L': count.L,
    }}
    {...boxProps}
  >
    {children}
  </Box>
);

export default FlexibleGridBox;
