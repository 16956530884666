import type { BaseElement } from '@/types/form';
import { Checkbox, type CheckboxProps } from '@mantine/core';
import type { FieldValues } from 'react-hook-form';

interface MercuryCheckboxProps<TFormValues extends FieldValues> extends
  Omit<CheckboxProps, 'name'>,
  BaseElement<TFormValues> { }

const MercuryCheckbox = <TFormValues extends FieldValues>({
  register,
  name,
  formOptions,
  ...inputProps
}: MercuryCheckboxProps<TFormValues>) => (
  <Checkbox
    {...register?.(name, formOptions)}
    {...inputProps}
  />
  );

export default MercuryCheckbox;
