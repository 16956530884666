import { Textarea, type TextareaProps } from '@mantine/core';

import BaseInput from './BaseInput';

import type { BaseElement } from '@/types/form';
import type { FieldValues } from 'react-hook-form';

interface MercuryTextAreaProps<TFormValues extends FieldValues> extends
  Omit<TextareaProps, 'name'>,
  BaseElement<TFormValues> { }

const MercuryTextArea = <TFormValues extends FieldValues>({
  register,
  name,
  formOptions,
  ...inputProps
}: MercuryTextAreaProps<TFormValues>) => (
  <BaseInput
    element={Textarea}
    minRows={5}
    id={name}
    {...register?.(name, formOptions)}
    {...inputProps}
    autosize
  />
  );

export default MercuryTextArea;
