import MercuryTextInput from '@/components/content/form/components/TextInput';
import type { FieldsetProps } from '../types';

interface QueryProps extends FieldsetProps {

}

const Query = ({ register }: QueryProps) => (
  <fieldset>
    <legend>Query<sup>*</sup></legend>

    <MercuryTextInput
      register={register}
      name="query"
      placeholder="Enter query"
      size="xs"
      disabled
      required
    />
  </fieldset>
);

export default Query;
