import { Box } from '@mantine/core';

import MercuryNumberInput from '@/components/content/form/components/NumberInput';
import At from './At';

import { parseMonthly, stringifyMonthly } from './parsers';

interface MonthlyProps {
  onChange: (value: string) => void;
  error?: string;
  value: string;
}

const Monthly = ({ value, error, onChange }: MonthlyProps) => {
  const parsed = parseMonthly(value);

  const onFrequencyChange = (val: number | string) => {
    onChange(stringifyMonthly(parsed?.minutes, parsed?.hours, [+val], parsed?.timezone));
  };

  return (
    <Box mt={10}>
      <MercuryNumberInput
        name="value"
        label="On Day"
        description="Max 31 days"
        value={parseInt(parsed?.days[0] ?? '0', 10)}
        error={error}
        size="xs"
        onChange={onFrequencyChange}
        min={1}
        max={31}
      />

      <At
        parsed={parsed}
        onChange={(minute, hour, timezone) => (
          onChange(stringifyMonthly(minute, hour, parsed?.days as Array<string | number>, timezone))
        )}
        error={error}
      />
    </Box>
  );
};

export default Monthly;
