import { useRef } from 'react';
import { Group, Input, type InputProps } from '@mantine/core';
import { useLocale, useTimeField } from 'react-aria';
import { type TimeFieldStateOptions, useTimeFieldState } from 'react-stately';

import { DateSegment } from '../common/Segment';

interface TimeFieldProps {
  timefieldProps: Omit<TimeFieldStateOptions, 'locale'>;
  inputProps?: InputProps;
}

const TimeField = ({ timefieldProps, inputProps }: TimeFieldProps) => {
  const ref = useRef(null);
  const { locale } = useLocale();
  const state = useTimeFieldState({ ...timefieldProps, locale });
  const { fieldProps } = useTimeField(timefieldProps, state, ref);

  return (
    <div>
      {timefieldProps.label && <Input.Label size={inputProps?.size}>{timefieldProps.label}</Input.Label>}

      <Input component="div" styles={{ input: { height: 'auto' } }} error={state.isInvalid} {...inputProps}>
        <Group {...fieldProps} ref={ref} align="center" wrap="nowrap" gap={2}>
          {state.segments.map((segment, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <DateSegment key={`segment-${i}`} segment={segment} state={state} />
          ))}
        </Group>
      </Input>
    </div>
  );
};

export default TimeField;
